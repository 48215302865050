import ServiceConceptImg from "../../../assets/bg-2.jpg";
export const SectionOne = () => {
  return (
    <section className="w-full min-h-96  pt-20 pb-10 md:pt-28 md:pb-10 relative">
      <div className="overlay absolute top-0 right-0 bottom-0 left-0 z-40"></div>

      <div className="max-w-screen-xl p-0.5 md:p-4 mx-auto relative z-30 overflow-hidden">
        <div className="w-[calc(100%+50%)] md:w-full mt-96 md:mt-56">
          <img src={ServiceConceptImg} className="w-full h-auto" alt="Motive - Architect And Engineer Ltd." />
        </div>
        
        <div className="bg-logoSky-400 text-white w-full md:w-[calc(50%-16px)] min-h-7xl px-8 md:px-12 py-16 md:py-24 absolute right-0 md:right-4 top-0 ">
          <small className="relative w-full top-0 left-0 after:content-['_ '] after:block after:bg-white after:w-24 after:h-1.5 after:absolute after:top-12 after:-left-14 md:after:-left-12 font-montserrat font-semibold text-md md:text-base">Promising Company</small>
          <h2 className="font-montserrat font-bold text-2xl md:text-5xl lg:text-6xl mt-16 ml-0 leading-lose">This Is What we Do</h2>
          <p className="mt-10 leading-lose">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
        </div>
      </div>
    </section>
  )
}
