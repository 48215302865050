
import { FaArrowLeft, FaArrowRight, FaRegWindowClose  } from "react-icons/fa";

export const ImgModal = ({clickedImg, setClickedImg, handelNext, handlePrev}) => {

  const handleDismiss = () => {
    setClickedImg(null);
  }

  return (
    <div className="modalWrapper relative z-40">
      <button onClick={handleDismiss} className="fixed w-10 h-10 top-32 md:top-28 lg:top-32 right-2 md:right-10 lg:right-20 flex items-center justify-center z-40 text-white text-3xl" title="Close Modal"><FaRegWindowClose /></button>
      <div className="fixed top-0 right-0 bottom-0 left-0 bg-gray-950 bg-opacity-85 flex items-center justify-center lg:gap-10">
        <button
          onClick={handlePrev}
          className="mt-20 w-10 h-10 text-white text-3xl" 
          title="Previous Image">
            <FaArrowLeft />
        </button>
        <div className="w-56 md:w-96 lg:w-[640px] h-auto mt-24">
          <img src={clickedImg} className="w-full" alt="" /> 
        </div>
        <button 
          onClick={handelNext}
          className="mt-20 w-10 h-10 text-white text-3xl" 
          title="Next Image">
            <FaArrowRight/>
        </button>
      </div>
    </div>
  )
}
