import { FaAngleRight } from "react-icons/fa6";
import BgImage from "../../../assets/bg-abstract.jpg";

export const SectionTwo = () => {
  return (
    <section style={{backgroundImage: `url(${BgImage})`}} className="w-full h-[1000px] md:h-[750px] bg-no-repeat bg-center bg-cover relative md:bg-fixed py-5 md:py-40 mt-10 md:mt-0 mb-56 md:mb-[200px]">
      <div className="overlay absolute top-0 right-0 bottom-0 left-0 bg-transparent dark:bg-transparent dark:bg-opacity-40 transition-colors"></div>
      <div className="max-w-screen-xl p-4 mx-auto flex flex-col-reverse md:flex-row item-center justify-between gap-10 md:gap-0">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <small className="relative w-full mx-auto top-0  left-0 -translate-x-1/2 md:translate-x-auto md:left-12 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-12 after:left-1/2 after:-translate-x-1/2 md:after:translate-x-0 md:after:-left-12 font-montserrat font-semibold text-md md:text-base text-gray-800 dark:text-gray-900">Promising Company</small>
          <h2 className="font-montserrat font-bold text-2xl md:text-5xl lg:text-6xl mt-16 ml-0 md:ml-10 z-50 text-gray-800 dark:text-gray-900 md:pr-20">Why Work With Us</h2>
        </div>
        <div className="w-full md:w-1/2 relative">
          <div className="bg-logoSky-400 text-white min-h-7xl p-12 absolute md:top-auto top-48 z-40 shadow-2xl">
            <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-start gap-5 md:mb-5 mb-10">
              <FaAngleRight className="text-xl md:text-6xl md:hover:text-7xl transition-all" />
              <div className="md:mt-3 text-center md:text-left">
                <h4 className="text-xl md:text-2xl font-montserrat font-semibold mb-2">Self Motivated</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore voluptatem explicabo, nobis corrupti officia temporibus?</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-start gap-5 md:mb-5 mb-10">
              <FaAngleRight className="text-xl md:text-6xl md:hover:text-7xl transition-all" />
              <div className="md:mt-3 text-center md:text-left">
                <h4 className="text-xl md:text-2xl font-montserrat font-semibold mb-2">We Meet Deadlines</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore voluptatem explicabo, nobis corrupti officia temporibus?</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-start gap-5 md:mb-5 mb-10">
              <FaAngleRight className="text-xl md:text-6xl md:hover:text-7xl transition-all" />
              <div className="md:mt-3 text-center md:text-left">
                <h4 className="text-xl md:text-2xl font-montserrat font-semibold mb-2">Sustainable Design</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore voluptatem explicabo, nobis corrupti officia temporibus?</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-start gap-5 md:mb-5 mb-10">
              <FaAngleRight className="text-xl md:text-6xl md:hover:text-7xl transition-all" />
              <div className="md:mt-3 text-center md:text-left">
                <h4 className="text-xl md:text-2xl font-montserrat font-semibold mb-2">Affordable Price</h4>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore voluptatem explicabo, nobis corrupti officia temporibus?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
