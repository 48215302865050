import { PageBanner } from "../../components";
import { SectionOne } from "./components/SectionOne";
import { SectionTwo } from "./components/SectionTwo";
import BannerImage from "../../assets/banner-4.jpg";
import { useDocTitle } from "../../hooks";

export const Contact = () => {
  useDocTitle("Motive / Contact Us");
  return (
    <main>
      <PageBanner bgImage={BannerImage} smallTitle={"Get In Touch"} h1Title={"Contact"} />
      <SectionOne />
      <SectionTwo />
    </main>
  )
}
