import { useState } from "react";
import { FaArrowsUpDownLeftRight } from "react-icons/fa6";
import { ImgModal } from "./ImgModal";

export const PortfolioCard = ({image, index, images}) => {
  const [isHover, setIsHover] = useState(false);
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (image, index) => {
    setClickedImg(image.url);
    setCurrentIndex(index);
  }

  const handelNext = () => {
    // setCurrentIndex(currentIndex + 1);
    if(currentIndex + 1 >= images.length) {
      setCurrentIndex(0);
      const newUrl = images[0].url
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = images.filter(item => {
      return images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);

  }

  const handlePrev = () => {
    
    if(currentIndex === 0) {
      setCurrentIndex(images.length - 1);
      const newUrl = images[images.length - 1].url;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = images.filter(item => {
      return images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  }

  return (
    <>
      <div 
        onClick={() => handleClick(image, index)} 
        onMouseEnter={() => setIsHover(true)} 
        onMouseLeave={() => setIsHover(false)} 
        style={{backgroundImage: `url(${image.url})`}} 
        className="portfolioImageCard w-80 h-56 bg-no-repeat bg-center bg-cover flex items-center justify-center relative overflow-hidden">

        <div className={`${isHover ? "bg-gray-900 bg-opacity-30" : "bg-transparent bg-opacity-0"} transition-all duration-200 delay-50 w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20`}></div>
        <FaArrowsUpDownLeftRight className={`${isHover ? "visible" : "hidden"} text-3xl text-white relative z-30`} />
        {/* <p className="bg-gray-950 text-white w-full text-center px-1 py-2 line-clamp-1">{image.title}</p> */}
      </div>
      
      { clickedImg && 
        <ImgModal 
          clickedImg={clickedImg}
          setClickedImg={setClickedImg}
          handelNext={handelNext}
          handlePrev={handlePrev}
        />
      }
    </>

  )
}
