import { FaAngleRight } from "react-icons/fa6";
import AboutUsImage from "../../../assets/about-owners-1.jpg";

export const AboutSection = () => {
  return (
    <section className="relative z-10">
      <div className="container max-w-screen-xl min-h-96 px-4 py-16 mb-5 mx-auto flex flex-col md:flex-row md:items-start  justify-between gap-5">
        <div className="flex-0 lg:w-2/5 mt-0 md:mt-24 lg:mt-28 text-gray-700 dark:text-gray-900">
          <small className="block font-montserrat  after:content-['_ '] after:w-24 after:h-1.5 after:bg-logoSky-400 after:block after:mt-5 after:mb-8 md:after:mb-16 after:mx-auto md:after:-ml-10 md:ml-10 text-lg text-center md:text-left">A few words</small>
          <h2 className="mb-4 text-4xl md:text-5xl lg:text-6xl text-center md:text-left font-bold font-montserrat tracking-tight leading-none text-gray-700 dark:text-gray-900 md:ml-10 ">About Us</h2>
        </div>
        <div className="flex-1 lg:w-3/5 flex flex-col lg:flex-row items-center justify-start gap-5">
          <div className="md:w-3/5 text-center">
            <img src={AboutUsImage} className="w-96 lg:w-full mx-auto shadow-2xl grayscale hover:grayscale-0 transition" alt="Motive - Architect and Engineer Ltd" />
          </div>
          <div className="lg:w-2/5 w-full text-gray-700 dark:text-gray-900">
            <h2 className="text-2xl font-montserrat">Interior Design Firm Based in Dhaka</h2>
            <p className="mt-5">A descriptive paragraph that tells clients how good you are and proves that you are the best choice that they've made. This paragraph is also for those who are looking out for a reliable interior design firm.</p>
            <p className="mt-5">You can use a few enticing words and flaunt your capabilities that will attract future clients and encourage them to hire you right away.</p>
            <button className="w-40 mt-5 px-4 py-2 flex items-center justify-between hover:bg-sky-300 hover:text-white border border-gray-600 hover:border-sky-300  rounded-full transition">Know More <FaAngleRight /></button>
          </div>
        </div>
        
      </div>
    </section>
  )
}
