import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { IconContext } from "react-icons";
import { ScrollToTop } from './components';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <IconContext.Provider value={{className: "inline-block"}}>
        <ScrollToTop />
        <App />
      </IconContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
);
