import { SectionOne } from "./components/SectionOne";
import { SectionTwo } from "./components/SectionTwo";
import { PageBanner } from "../../components";
import { useDocTitle } from "../../hooks";
import ServiceBanner from "../../assets/banner-2.jpg";

export const Services = () => {
  useDocTitle("Motive / Services");

  return (
    <main>
      <PageBanner bgImage={ServiceBanner} smallTitle={"Global Perspective"} h1Title={"Services"}/>
      <SectionOne />
      <SectionTwo />
    </main>
  )
}
