import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import HeroBg from "../../../assets/hero.jpg";

export const HeroSection = () => {

  return (
    <section style={{backgroundImage: `url(${HeroBg})`}} className="heroSection w-full min-h-svh md:min-h-dvh md:bg-fixed bg-center bg-no-repeat bg-cover relative -top-20 left-0 z-10">
      <div className="overlay w-full min-h-svh md:min-h-dvh bg-transparent dark:bg-gray-400 dark:bg-opacity-20 absolute top-0 left-0 z-30"></div>
      <div className="container max-w-screen-xl min-h-svh md:min-h-dvh p-4 mx-auto flex flex-col items-start justify-center relative overflow-hidden z-40">
        <div className="text-gray-800 dark:text-gray-950">
          <small className="block font-montserrat  after:content-['_ '] after:w-24 after:h-1.5 after:bg-logoSky-400 after:block after:mt-5 after:mb-16 after:-ml-10 text-lg ml-10 ">Original And Creative In Thinking</small>
          <h1 className="mb-4 text-4xl font-bold font-montserrat tracking-tight leading-none text-gray-800 md:text-7xl lg:text-8xl dark:text-gra-700 ml-10">Ingenious <span className="md:bg-logoSky-400 md:bg-opacity-85 md:text-logoBlue-400 leading-snug ">Design &</span> Architecture</h1>
          {/* <small className="ml-10 mb-10 text-lg md:w-96 block">Interior Design Doesn't Have to Be Expensive or Complicated! It Should Be Smart & User Friendly</small> */}
        </div>
        <div className="w-full md:w-1/2 h-32 bg-white dark:bg-gray-200 absolute -bottom-5 left-0 p-4 md:p-0 mx-auto">
          <Link to="/" className="hover:text-logoSky-400 dark:hover:text-logoSky-400 text-gray-800 h-full p-12 flex items-center justify-between">
            <span>Read More</span>
            <FaLongArrowAltRight size={20} />
          </Link>
        </div>
      </div>
    </section>
  )
}
