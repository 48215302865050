import { PageBanner } from "../../components";
import { SectionOne } from "./components/SectionOne";
import { SectionTwo } from "./components/SectionTwo";
import { useDocTitle } from "../../hooks";
import AboutBanner from "../../assets/banner-1.jpg";

export const About = () => {
  useDocTitle("Motive / About Us");
  
  return (
    <main>
      <PageBanner bgImage={AboutBanner} smallTitle={"A Few Words"} h1Title={"About Us"}/>
      <SectionOne />
      <SectionTwo />
    </main>
  )
}
