export const PageBanner = ({bgImage, smallTitle, h1Title}) => {
  return (
    <section 
      style={{backgroundImage: `url(${bgImage})`}}
      className="w-full h-60 md:h-96 bg-no-repeat bg-center bg-cover relative">
      <div className="overlay absolute top-0 right-0 bottom-0 left-0 bg-transparent dark:bg-gray-500 dark:bg-opacity-30 transition-colors"></div>
      <div className="container relative max-w-screen-xl h-full px-4 mx-auto z-40">
        <div className="md:w-[calc(50%-16px)] w-1/2 h-40 md:h-48 bg-white dark:bg-gray-200 absolute bottom-0 left-0 md:left-4">
          <small className="relative w-full top-8 md:top-12 left-[16px] md:left-12 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-12 after:-left-12 font-montserrat font-semibold text-md md:text-base text-gray-800 dark:text-gray-900">{smallTitle}</small>
          <h1 className="font-montserrat font-bold text-3xl md:text-6xl lg:text-8xl mt-24 md:mt-28 ml-[16px] md:ml-10 z-50 text-gray-800 dark:text-gray-900">{h1Title}</h1>
        </div>
      </div>
    </section>
  )
}
