import { HeroSection } from "./components/HeroSection";
import { AboutSection } from "./components/AboutSection";
import { ServicesSection } from "./components/ServicesSection";
import { FaqSection } from "./components/FaqSection";
import { useDocTitle } from "../../hooks";
export const Home = () => {
  useDocTitle("Motive / Home")
  return (
    <main>
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <FaqSection />
    </main>
  )

}
