import { FaRegCalendarCheck, FaCube, FaChair, FaRulerCombined, FaHome, FaBriefcase   } from "react-icons/fa";

export const ServicesSection = () => {
  return (
    <section className="bg-gray-100 dark:bg-slate-500">
      <div className="overlay">
        <div className="container max-w-screen-xl min-h-96 px-4  py-20 md:py-32 mx-auto text-gray-800 dark:text-white">
          <small className="block font-montserrat after:content-['_ '] after:w-24 after:h-1.5 after:bg-blue-300 after:block after:mt-5 after:mb-6 after:mx-auto text-base md:text-lg text-center">This Is What We Do</small>
          <h2 className="mb-20 text-3xl md:text-5xl lg:text-6xl text-center font-bold font-montserrat tracking-tight leading-none text-gray-800 dark:text-white line-clamp-1">Services</h2>

          <div className="flex flex-col md:flex-row items-center justify-between gap-20 text-center text-gray-800 dark:text-white my-20">
            <div className="flex flex-col items-center justify-center gap-3">
              <FaRegCalendarCheck className="text-sky-300 text-2xl md:text-4xl mb-10" />
              <h4 className="text-xl md:text-2xl font-montserrat font-semibold">Building Planning</h4>
              <p>This is a short description elaborating the service you have mentioned above.​</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <FaCube className="text-sky-300 text-2xl md:text-4xl mb-10" />
              <h4 className="text-xl md:text-2xl font-montserrat font-semibold">Interior Space</h4>
              <p>This is a short description elaborating the service you have mentioned above.​</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <FaChair className="text-sky-300 text-2xl md:text-4xl mb-10" />
              <h4 className="text-xl md:text-2xl font-montserrat font-semibold">Furniture Design</h4>
              <p>This is a short description elaborating the service you have mentioned above.​</p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-between gap-20 text-center dark:text-white mt-10">
            <div className="flex flex-col items-center justify-center gap-3">
              <FaRulerCombined className="text-sky-300 text-2xl md:text-4xl mb-10" />
              <h4 className="text-xl md:text-2xl font-montserrat font-semibold">Renovating Space</h4>
              <p>This is a short description elaborating the service you have mentioned above.​</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <FaHome className="text-sky-300 text-2xl md:text-4xl mb-10" />
              <h4 className="text-xl md:text-2xl font-montserrat font-semibold">Structural Engineering</h4>
              <p>This is a short description elaborating the service you have mentioned above.​</p>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <FaBriefcase  className="text-sky-300 text-2xl md:text-4xl mb-10" />
              <h4 className="text-xl md:text-2xl font-montserrat font-semibold">Free Consultation</h4>
              <p>This is a short description elaborating the service you have mentioned above.​</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
