import { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BsToggle2Off, BsToggle2On  } from "react-icons/bs";
import { FaBars, FaTimes } from "react-icons/fa";

import { MenuItem } from "../";

import Logo from "../../assets/logo.svg";

export const Header = () => {
  
  const getDarkModeState = JSON.parse(localStorage.getItem("motive-dark-mode"));
  const [darkModeToggle, setDarkModeToggle] = useState(getDarkModeState ? getDarkModeState : false);
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);
  const headerRef = useRef(null);
  const menuWrapperRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if(darkModeToggle) {
      document.documentElement.classList.add("dark");
      document.body.classList.add("bg-gray-300");
      document.body.classList.add("transition");
      document.body.classList.add("bg-opacity-50");
    } else {
      document.documentElement.removeAttribute("class");
      document.body.classList.remove("transition");
      document.body.classList.remove("bg-gray-300");
      document.body.classList.remove("bg-opacity-50");
    }
    localStorage.setItem("motive-dark-mode", JSON.stringify(darkModeToggle));
  }, [darkModeToggle]);

  useEffect(() => {
    const headerEl = headerRef.current;
    window.onscroll = (e) => {
      window.scrollY > headerEl.offsetHeight ? headerEl.classList.add("sticky") : headerEl.classList.remove("sticky");
      setStickyHeader(headerEl.classList.contains("sticky"));
    }
  }, [headerRef.current]); //eslint-disable-line
 
  useEffect(() => {
    if(!stickyHeader && location.pathname !== "/") {
      headerRef.current.children[0].children[0].classList.add("h-28");
      headerRef.current.classList.add("border-b-4");
      if(!darkModeToggle) {
        headerRef.current.classList.remove("bg-stickyHeaderDark-400");
        headerRef.current.classList.add("bg-white");
      } else {
        headerRef.current.classList.remove("bg-white");
        headerRef.current.classList.add("bg-stickyHeaderDark-400");
      }
    } else {
      headerRef.current.children[0].children[0].classList.remove("h-28");
      headerRef.current.classList.remove("border-b-4");
      headerRef.current.classList.remove("bg-stickyHeaderDark-400");
      headerRef.current.classList.remove("bg-white");
    }
  }, [stickyHeader, darkModeToggle, location.pathname]);

  const menus = [
    {
      id: 1,
      text: "Home",
      url: "/"
    },
    {
      id: 2,
      text: "About",
      url: "about"
    },
    {
      id: 3,
      text: "Services",
      url: "services"
    },
    {
      id: 4,
      text: "Portfolio",
      url: "portfolio"
    },
    {
      id: 5,
      text: "Contact",
      url: "contact"
    }
  ];

  return (
    <header ref={headerRef} className="relative z-50 transition-all">
      <nav>
        <div  className="max-w-screen-xl flex flex-wrap items-center justify-between gap-4 mx-auto p-4">
          <NavLink to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={Logo} className="h-8" alt="Motive Architect And Engineer Ltd" />
          </NavLink>

          <button onClick={() => setMobileMenuToggle(!mobileMenuToggle)} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white md:hidden bg-logoSky-400 focus:outline-none focus:ring-2 focus:ring-logoBlue-400 dark:text-white dark:focus:ring-logoBlue-400 transition">
            <span className="sr-only">Open main menu</span>
            {mobileMenuToggle ? <FaTimes className="w-5 h-5" /> : <FaBars className="w-5 h-5" />}
          </button>

          <div ref={menuWrapperRef} className={`${mobileMenuToggle ? "block" : "hidden"} md:block flex-1 min-w-full min-h-dvh md:min-h-0 md:min-w-0 absolute ${stickyHeader ? "top-[72px]" : "top-[116px]"} left-0 md:relative md:top-0 md:left-0 md:w-auto bg-gray-200 md:bg-transparent `}>
            <ul className="w-full flex flex-col md:justify-end font-medium px-4 p-10 md:p-0  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0">
              {menus.map(menu => (
                <MenuItem key={menu.id} menu={menu} setMobileMenuToggle={setMobileMenuToggle} />
              ))}
              <li className="flex items-center gap-2 text-gray-900 ml-4 md:ml-0">
                {darkModeToggle ? 
                (<><button onClick={() => setDarkModeToggle(!darkModeToggle)}><BsToggle2On className="text-logoSky-400" /></button> <span>Dark Mode On</span></>) : 
                (<><button onClick={() => setDarkModeToggle(!darkModeToggle)}><BsToggle2Off  /> </button> <span>Dark Mode Off</span></>)}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
