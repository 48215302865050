import AboutArchitectInfrastructure from "../../../assets/about-architects-infrastructure.png";
export const SectionOne = () => {
  return (
    <section className="py-20 md:py-40">
      <div className="max-w-screen-xl p-4 mx-auto flex flex-col-reverse md:flex-row item-center justify-between gap-10 md:gap-0">
        <div className="md:w-1/2">
          <small className="relative w-full top-8 md:top-12 left-0 md:left-12 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-12 after:-left-12 font-montserrat font-semibold  text-md md:text-base text-gray-800 dark:text-gray-900">Promising Company</small>
          <h2 className="font-montserrat font-bold text-2xl md:text-5xl lg:text-6xl mt-24 md:mt-28 ml-0 md:ml-10 z-50 text-gray-800 dark:text-gray-900">Making a Difference</h2>
          <p className="ml-0 md:ml-10 mt-6 md:mt-10 md:pr-10">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
        </div>
        <div className="md:w-1/2">
          <img src={AboutArchitectInfrastructure} className="w-full" alt="Motive - Architect And Engineer Ltd" />
        </div>
      </div>
      </section>
  )
}
