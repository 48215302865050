import { Link } from "react-router-dom";
import { useDocTitle } from "../hooks";
import Logo from "../assets/logo.svg";

export const PageNotFound = () => {
  useDocTitle("Motive / Page Not Found");

  return (
    <main>
      <div className="max-w-screen-sm h-svh p-4 mx-auto font-montserrat flex flex-col items-center justify-center">
        <small className="text-lg md:text-xl font-semibold">Oops, 404 Error!</small>
        <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold">Page Not Found!</h1>
        <img className="w-[300px] md:w-full my-10" src={Logo} alt="Motive - Architects And Engineers Ltd." />
        <Link to="/" className="w-40 p-2 bg-logoSky-400 hover:bg-sky-300 text-white text-center rounded">Go Home</Link>
      </div>
    </main>
  )
}
