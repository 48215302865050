import { FaPhone, FaEnvelope } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
export const SectionTwo = () => {
  return (
    <section className="w-full min-h-96 mb-20">
        <div className="max-w-screen-xl p-0 md:p-4 mx-auto">
          <div className="w-full flex flex-wrap flex-col md:flex-row items-start justify-between">
            <div className="w-full md:w-1/2 py-28 bg-logoSky-400 text-white h-[550px]">
              <small className="md:ml-16 ml-4 text-md md:text-base font-montserrat font-semibold">Don't Be A Stranger</small>
              <span className="w-24 h-1.5 mt-8 mb-10 bg-white block"></span>
              <h2 className="md:ml-16 ml-4 text-2xl md:text-5xl lg:text-6xl font-montserrat font-bold">Contact Us</h2>
              <ul className="md:ml-16 ml-4 mt-16">
                <li className="my-5 flex items-start justify-start gap-5 md:gap-10">
                  <FaPhone/> <span>Phone: - +8801234 567 890</span>
                </li>
                <li className="my-5 flex items-start justify-start gap-5 md:gap-10">
                  <FaEnvelope/> <span>Email: - motiveltd@gmail.com</span>
                </li>
                <li className="my-5 flex items-start justify-start gap-5 md:gap-10">
                  <FaLocationDot/> <span>Address: - 123, Fifth Avenue, New York, NY 10160, USA</span>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/2">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29240.29839948466!2d90.34792959999999!3d23.638835200000038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bc500385cc05%3A0x84a88899cbf898ed!2sBikrampur%20Adarsha%20College!5e0!3m2!1sen!2sbd!4v1706950348400!5m2!1sen!2sbd" 
                title="gMap"
                width="100%" 
                height="550" 
                allowfullscreen="1" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"
                style={{border: "0"}}>
              </iframe>
            </div>
          </div>
        </div>
      </section>
  )
}
