import { NavLink } from "react-router-dom";

export const MenuItem = ({menu, setMobileMenuToggle}) => {
  const activeClass = "block py-2 px-3 text-white bg-logoBlue-400 rounded md:bg-transparent md:text-logoSky-400 md:p-0 md:dark:text-logoSky-400 dark:bg-logoBlue-400 md:dark:bg-transparent font-montserrat";
  const inActiveClass = "block py-2 px-3 text-gray-800 rounded hover:bg-logoBlue-400 hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-logoSky-400 md:p-0 md:dark:hover:text-logoSky-400 dark:hover:bg-logoBlue-400 dark:hover:text-white md:dark:hover:bg-transparent montserrat";
  return (
    <li>
      <NavLink onClick={() => setMobileMenuToggle(false)} to={menu.url} className={({isActive}) => isActive ? activeClass : inActiveClass}>{menu.text}</NavLink>
    </li>
  )
}
