export const SectionTwo = () => {
  return (
    <section className="mb-10 md:mb-20">
    <div className="max-w-screen-xl p-4 mx-auto text-gray-800 dark:gray-900 overflow-hidden">
      <div className="flex flex-wrap items-center justify-between">
        <div className="w-full md:w-1/2 my-10">
          <small className="ml-0 md:ml-16 font-montserrat font-semibold text-base">- 01</small>
          <h4 className="relative w-full font-montserrat font-bold text-xl md:text-3xl mt-5 ml-0 md:ml-16 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-16 after:-left-10 md:after:-left-16">Planing</h4>
          <p className="mt-16 ml-0 md:ml-16 md:pr-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>

        <div className="w-full md:w-1/2 my-10">
          <small className="ml-0 md:ml-16 font-montserrat font-semibold text-base">- 02</small>
          <h4 className="relative w-full font-montserrat font-bold text-xl md:text-3xl mt-5 ml-0 md:ml-16 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-16 after:-left-10 md:after:-left-16">Interior Design</h4>
          <p className="mt-16 ml-0 md:ml-16 md:pr-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>

        <div className="w-full md:w-1/2 my-10">
          <small className="ml-0 md:ml-16 font-montserrat font-semibold text-base">- 03</small>
          <h4 className="relative w-full font-montserrat font-bold text-xl md:text-3xl mt-5 ml-0 md:ml-16 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-16 after:-left-10 md:after:-left-16">Furniture Design</h4>
          <p className="mt-16 ml-0 md:ml-16 md:pr-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>

        <div className="w-full md:w-1/2 my-10">
          <small className="ml-0 md:ml-16 font-montserrat font-semibold text-base">- 04</small>
          <h4 className="relative w-full font-montserrat font-bold text-xl md:text-3xl mt-5 ml-0 md:ml-16 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-16 after:-left-10 md:after:-left-16">Renovating Space</h4>
          <p className="mt-16 ml-0 md:ml-16 md:pr-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>

        <div className="w-full md:w-1/2 my-10">
          <small className="ml-0 md:ml-16 font-montserrat font-semibold text-base">- 05</small>
          <h4 className="relative w-full font-montserrat font-bold text-xl md:text-3xl mt-5 ml-0 md:ml-16 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-16 after:-left-10 md:after:-left-16">Structural Engineering</h4>
          <p className="mt-16 ml-0 md:ml-16 md:pr-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>

        <div className="w-full md:w-1/2 my-10">
          <small className="ml-0 md:ml-16 font-montserrat font-semibold text-base">- 06</small>
          <h4 className="relative w-full font-montserrat font-bold text-xl md:text-3xl mt-5 ml-0 md:ml-16 after:content-['_ '] after:block after:bg-logoSky-400 after:w-24 after:h-1.5 after:absolute after:top-16 after:-left-10 md:after:-left-16">Consultation</h4>
          <p className="mt-16 ml-0 md:ml-16 md:pr-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
        </div>
        
      </div>
    </div>
  </section>
  )
}
