import { PageBanner } from "../../components";
import { SectionOne } from "./components/SectionOne";
import { useDocTitle } from "../../hooks";
import PortfolioBanner from "../../assets/banner-3.jpg";

export const Portfolio = () => {
  useDocTitle("Motive / Portfolio");

  return (
    <main>
      <PageBanner bgImage={PortfolioBanner} smallTitle={"Few Work Sample"} h1Title={"Portfolio"}/>
      <SectionOne />
    </main>
  )
}
