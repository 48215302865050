import { Header, Footer } from "./components" ;
import { AllRoutes } from "./routes";
import './App.css';

function App() {
  return (
    <>
      <Header />
      <AllRoutes />
      <Footer />
    </>
  );
}

export default App;
