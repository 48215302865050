import { PortfolioCard } from "../../../components";

export const SectionOne = () => {
  const images = [
    { id: 1, title: "Image Title", url: "/assets/images/portfolio/1.jpg" },
    { id: 2, title: "Image Title", url: "/assets/images/portfolio/2.jpg" },
    { id: 3, title: "Image Title", url: "/assets/images/portfolio/3.jpg" },
    { id: 4, title: "Image Title", url: "/assets/images/portfolio/4.jpg" },
    { id: 5, title: "Image Title", url: "/assets/images/portfolio/5.jpg" },
    { id: 6, title: "Image Title", url: "/assets/images/portfolio/6.jpg" },
    { id: 7, title: "Image Title", url: "/assets/images/portfolio/7.jpg" },
    { id: 8, title: "Image Title", url: "/assets/images/portfolio/8.jpg" },
    { id: 9, title: "Image Title", url: "/assets/images/portfolio/9.jpg" },
    { id: 10, title: "Image Title", url: "/assets/images/portfolio/10.jpg" },
    { id: 11, title: "Image Title", url: "/assets/images/portfolio/11.jpg" },
    { id: 12, title: "Image Title", url: "/assets/images/portfolio/12.jpg" },
    { id: 13, title: "Image Title", url: "/assets/images/portfolio/13.jpg" },
    { id: 14, title: "Image Title", url: "/assets/images/portfolio/14.jpg" },
    { id: 15, title: "Image Title", url: "/assets/images/portfolio/15.jpg" },
    { id: 16, title: "Image Title", url: "/assets/images/portfolio/16.jpg" },
    { id: 17, title: "Image Title", url: "/assets/images/portfolio/17.jpg" },
    { id: 18, title: "Image Title", url: "/assets/images/portfolio/18.jpg" },
    { id: 19, title: "Image Title", url: "/assets/images/portfolio/19.jpg" },
    { id: 20, title: "Image Title", url: "/assets/images/portfolio/20.jpg" },
    { id: 21, title: "Image Title", url: "/assets/images/portfolio/21.jpg" },
    { id: 22, title: "Image Title", url: "/assets/images/portfolio/22.jpg" },
    { id: 23, title: "Image Title", url: "/assets/images/portfolio/23.jpg" },
    { id: 24, title: "Image Title", url: "/assets/images/portfolio/24.jpg" },
    { id: 25, title: "Image Title", url: "/assets/images/portfolio/25.jpg" },
    { id: 26, title: "Image Title", url: "/assets/images/portfolio/26.jpg" },
    { id: 27, title: "Image Title", url: "/assets/images/portfolio/27.jpg" },
    { id: 28, title: "Image Title", url: "/assets/images/portfolio/28.jpg" },
    { id: 29, title: "Image Title", url: "/assets/images/portfolio/29.jpg" },
    { id: 30, title: "Image Title", url: "/assets/images/portfolio/30.jpg" },
    { id: 31, title: "Image Title", url: "/assets/images/portfolio/31.jpg" },
    { id: 32, title: "Image Title", url: "/assets/images/portfolio/32.jpg" },
    { id: 33, title: "Image Title", url: "/assets/images/portfolio/33.jpg" },
    { id: 34, title: "Image Title", url: "/assets/images/portfolio/34.jpg" },
    { id: 35, title: "Image Title", url: "/assets/images/portfolio/35.jpg" },
    { id: 36, title: "Image Title", url: "/assets/images/portfolio/36.jpg" },
    { id: 37, title: "Image Title", url: "/assets/images/portfolio/37.jpg" },
    { id: 38, title: "Image Title", url: "/assets/images/portfolio/38.jpg" },
    { id: 39, title: "Image Title", url: "/assets/images/portfolio/39.jpg" },
    { id: 40, title: "Image Title", url: "/assets/images/portfolio/40.jpg" },
    { id: 41, title: "Image Title", url: "/assets/images/portfolio/41.jpg" },
    { id: 42, title: "Image Title", url: "/assets/images/portfolio/42.jpg" },
    { id: 43, title: "Image Title", url: "/assets/images/portfolio/43.jpg" },
    { id: 44, title: "Image Title", url: "/assets/images/portfolio/44.jpg" },
    { id: 45, title: "Image Title", url: "/assets/images/portfolio/45.jpg" },
    { id: 46, title: "Image Title", url: "/assets/images/portfolio/46.jpg" },
    { id: 47, title: "Image Title", url: "/assets/images/portfolio/47.jpg" },
    { id: 48, title: "Image Title", url: "/assets/images/portfolio/48.jpg" },
    { id: 49, title: "Image Title", url: "/assets/images/portfolio/49.jpg" },
    { id: 50, title: "Image Title", url: "/assets/images/portfolio/50.jpg" },
    { id: 51, title: "Image Title", url: "/assets/images/portfolio/51.jpg" },
    { id: 52, title: "Image Title", url: "/assets/images/portfolio/52.jpg" },
    { id: 53, title: "Image Title", url: "/assets/images/portfolio/53.jpg" },
    { id: 54, title: "Image Title", url: "/assets/images/portfolio/54.jpg" },
    { id: 55, title: "Image Title", url: "/assets/images/portfolio/55.jpg" },
    { id: 56, title: "Image Title", url: "/assets/images/portfolio/56.jpg" },
    { id: 57, title: "Image Title", url: "/assets/images/portfolio/57.jpg" },
    { id: 58, title: "Image Title", url: "/assets/images/portfolio/58.jpg" },
    { id: 59, title: "Image Title", url: "/assets/images/portfolio/59.jpg" },
    { id: 60, title: "Image Title", url: "/assets/images/portfolio/60.jpg" },
    { id: 61, title: "Image Title", url: "/assets/images/portfolio/61.jpg" },
    { id: 62, title: "Image Title", url: "/assets/images/portfolio/62.jpg" },
    { id: 63, title: "Image Title", url: "/assets/images/portfolio/63.jpg" },
    { id: 64, title: "Image Title", url: "/assets/images/portfolio/64.jpg" },
    { id: 65, title: "Image Title", url: "/assets/images/portfolio/65.jpg" },
    { id: 66, title: "Image Title", url: "/assets/images/portfolio/66.jpg" },
    { id: 67, title: "Image Title", url: "/assets/images/portfolio/67.jpg" },
    { id: 68, title: "Image Title", url: "/assets/images/portfolio/68.jpg" },
    { id: 69, title: "Image Title", url: "/assets/images/portfolio/69.jpg" },
    { id: 70, title: "Image Title", url: "/assets/images/portfolio/70.jpg" },
    { id: 71, title: "Image Title", url: "/assets/images/portfolio/71.jpg" },
    { id: 72, title: "Image Title", url: "/assets/images/portfolio/72.jpg" },
    { id: 73, title: "Image Title", url: "/assets/images/portfolio/73.jpg" },
    { id: 74, title: "Image Title", url: "/assets/images/portfolio/74.jpg" },
    { id: 75, title: "Image Title", url: "/assets/images/portfolio/75.jpg" },
    { id: 76, title: "Image Title", url: "/assets/images/portfolio/76.jpg" },
    { id: 77, title: "Image Title", url: "/assets/images/portfolio/77.jpg" },
    { id: 78, title: "Image Title", url: "/assets/images/portfolio/78.jpg" },
    { id: 79, title: "Image Title", url: "/assets/images/portfolio/79.jpg" },
    { id: 80, title: "Image Title", url: "/assets/images/portfolio/80.jpg" },
    { id: 81, title: "Image Title", url: "/assets/images/portfolio/81.jpg" },
    { id: 82, title: "Image Title", url: "/assets/images/portfolio/82.jpg" },
    { id: 83, title: "Image Title", url: "/assets/images/portfolio/83.jpg" },
    { id: 84, title: "Image Title", url: "/assets/images/portfolio/84.jpg" },
    { id: 85, title: "Image Title", url: "/assets/images/portfolio/85.jpg" },
    { id: 86, title: "Image Title", url: "/assets/images/portfolio/86.jpg" },
    { id: 87, title: "Image Title", url: "/assets/images/portfolio/87.jpg" },
    { id: 88, title: "Image Title", url: "/assets/images/portfolio/88.jpg" },
    { id: 89, title: "Image Title", url: "/assets/images/portfolio/89.jpg" }
  ];

  return (
    <section className="w-full min-h-96 py-20 md:py-40 relative">
      <div className="overlay, absolute top-0 right-0 bottom-0 left-0 z-30"></div>
      <div className="max-w-screen-xl p-4 mx-auto">
        <div className="portfolioImageCards flex flex-wrap items-center justify-center gap-5 relative z-40">
          {images.map((image, index) => (
            <PortfolioCard key={image.id} image={image} index={index} images={images} />
          ))}
        </div>
      </div>
    </section>
  )
}
