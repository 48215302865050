import { NavLink, Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import BgImage from "../../assets/footer-img.jpg";

export const Footer = () => {

  return (
    <footer 
      style={{backgroundImage: `linear-gradient(to right,rgba(255,255,255,0.9),rgba(255,255,255,0.9)), url(${BgImage})`}} 
      className="heroSection bg-white dark:bg-gray-800 w-full min-h-96 md:bg-fixed bg-center bg-no-repeat bg-cover relative top-0 left-0 z-10">
      {/* overlay */}
      <div className="w-full min-h-96 bg-transparent dark:bg-gray-500 dark:bg-opacity-20">
        <div className="container max-w-screen-xl px-4 py-10 mx-auto relative overflow-hidden">
          <img src={Logo} className="h-6 block md:mt-20 mb-10" alt="Motive Architect And Engineer Ltd" />
          <div className="flex flex-col md:flex-row items-baseline justify-between gap-10 md:gap-20 w-full h-full">
            <div className="md:max-w-96">
              <h4 className="text-xl text-gray-700 dark:text-gray-900 font-montserrat mb-5">About Us</h4>
              <p>Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis.</p>
            </div>
            <div className="flex-1">
              <h4 className="text-xl text-gray-700 dark:text-gray-900 font-montserrat mb-5">Quick Links</h4>
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="about">About</NavLink></li>
                <li><NavLink to="services">Services</NavLink></li>
                <li><NavLink to="portfolio">Portfolio</NavLink></li>
                <li><NavLink to="contact">Contact</NavLink></li>
              </ul>
            </div>
            <div className="flex-1">
              <h4 className="text-xl text-gray-700 dark:text-gray-900 font-montserrat mb-5">Get In Touch</h4>
              <ul>
                <li><Link to="/">Facebook</Link></li>
                <li><Link to="/">Youtube</Link></li>
                <li><Link to="/">Linkedin</Link></li>
                <li><Link to="/">Instagram</Link></li>
                <li><Link to="/">X</Link></li>
              </ul>
            </div>
            <div className="flex-1">
              <h4 className="text-xl text-gray-700 dark:text-gray-900 font-montserrat mb-5">Contact Info</h4>
              <div>
                <a href="tel:123 456 7890">123 456 7890​</a> 
                <a href="mailto:architects@info.com">architects@info.com​</a>
                <p>123, Fifth Avenue, New York, NY 10160, USA</p>
              </div>
            </div>
          </div>
          <hr className="my-14" />
          <div>
            <p className="text-center text-sm">Copyright © 2024 Motive Architect And Engineer Ltd.</p>
            <p className="text-center text-xs">Developed by <Link to="https://www.facebook.com/NasirUddinShiplu/" target="_blank">Nasir Uddin Shiplu</Link></p>
          </div>
        </div>
      </div>
    </footer>
  )
}
