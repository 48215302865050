import { Faq } from "../../../components";
import BgImage from "../../../assets/bg-3.jpg";

export const FaqSection = () => {
  const faqs = [
    {id: 1, question: "What is Flowbite?", answer: "Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons, dropdowns, modals, navbars, and more."},
    {id: 2, question: "What is Flowbite?", answer: "Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons, dropdowns, modals, navbars, and more."},
    {id: 3, question: "What is Flowbite?", answer: "Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons, dropdowns, modals, navbars, and more."},
    {id: 4, question: "What is Flowbite?", answer: "Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons, dropdowns, modals, navbars, and more."},
    {id: 5, question: "What is Flowbite?", answer: "Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons, dropdowns, modals, navbars, and more."}
  ];

  return (
    
    <section style={{backgroundImage: `url(${BgImage})`}} className="w-full h-[800px] md:h-[700px] bg-no-repeat bg-center bg-cover md:bg-fixed pt-20 md:pt-40 mb-40 relative">
      <div className="overlay absolute top-0 right-0 bottom-0 left-0 z-30"></div>
      <div className="container max-w-screen-xl md:min-h-96 px-4 mx-auto flex flex-col md:flex-row items-start justify-between gap-10 md:gap-0 text-gray-800 dark:text-gray-900">
        <div className="w-full md:w-1/2 relative z-40">
          <small className="block font-montserrat after:content-['_ '] after:w-24 after:h-1.5 after:bg-blue-300 after:block after:mt-5 after:mb-8 md:after:mb-16 after:mx-auto md:after:-ml-10 md:ml-10 text-lg text-center md:text-left">Questions in mind</small>
          <h2 className="mb-4 text-3xl md:text-5xl lg:text-6xl text-center md:text-left font-bold font-montserrat tracking-tight leading-none text-gray-800 dark:text-gray-900  md:ml-10 ">Frequently Asked Question</h2>
        </div>
        <div className="w-full md:w-1/2 relative z-40 mb-32">
          <div className="bg-logoSky-400 w-full px-12 py-20 shadow-2xl absolute top-0 right-0 z-50">
            {faqs.map(faq => (
              <Faq key={faq.id} faq={faq} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
