
export const SectionOne = () => {
  return (
    <section className="w-full min-h-96 pt-32 pb-20 relative">
      <div className="overlay absolute top-0 right-0 bottom-0 left-0 z-30"></div>
      <div className="max-w-screen-xl p-4 mx-auto flex flex-col md:flex-row item-center justify-between gap-10 relative z-40">
        <div className="md:w-1/2">
          <small className="relative w-full top-0 left-0 md:left-12 font-montserrat font-semibold  text-md md:text-base text-gray-800 dark:text-gray-900">Have Any Queries?</small>
          <h2 className="font-montserrat font-bold text-2xl md:text-5xl lg:text-6xl p-0 md:pr-10 mt-10 ml-0 md:ml-10 text-gray-800 dark:text-gray-900 relative">Let's Get In Touch</h2>
          <p className="ml-0 md:ml-10 mt-6 md:mt-10 md:pr-10">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
        <div className="md:w-1/2">
          <form className="bg-white dark:bg-gray-200 px-10 py-20 shadow-2xl flex flex-col items-start justify-center gap-5 text-sm">
            <div className="w-full">
              <input className="w-full border border-gray-300 bg-gray-100 focus:bg-transparent p-2 outline-0" type="text" name="name" placeholder="Name" autoComplete="off" />
              <span className="error text-rose-500 text-xs"></span>
            </div>
            <div className="w-full">
              <input className="w-full border border-gray-300 bg-gray-100 focus:bg-transparent p-2 outline-0" type="email" name="email" placeholder="Email" autoComplete="off" />
              <span className="error text-rose-500 text-xs"></span>
            </div>
            <div className="w-full">
              <input className="w-full border border-gray-300 bg-gray-100 focus:bg-transparent p-2 outline-0" type="text" name="subject" placeholder="Subject" autoComplete="off" />
              <span className="error text-rose-500 text-xs"></span>
            </div>
            <div className="w-full">
              <textarea className="w-full min-h-40 border border-gray-300 bg-gray-100 focus:bg-transparent p-2 outline-0" name="message" placeholder="Message" autoComplete="off" minLength="50" maxLength="500"></textarea>
              <span className="error text-rose-500 text-xs"></span>
            </div>
            <button className="w-40 p-3 bg-logoSky-400 hover:bg-sky-300 text-white uppercase rounded">Send Message</button>
          </form>
        </div>
      </div>
    </section>
  )
}
