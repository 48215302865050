import { useState } from "react";
import { FaRegQuestionCircle, FaAngleDown, FaAngleUp  } from "react-icons/fa";

export const Faq = ({faq}) => {
  const [showHideToggle, setShoHideToggle] = useState(false);

  return (
    <div className="p-2">
      <h4>
        <button onClick={() => setShoHideToggle(!showHideToggle)} type="button" className={`flex items-center justify-between w-full p-5 my-1 font-medium rtl:text-right border border-white gap-3 text-white hover:scale-110 transition-all`}>
          <span className="flex items-center gap-2">
            <FaRegQuestionCircle className={`md:text-3xl`}/>
            {faq.question}
          </span>
          {showHideToggle ? <FaAngleUp /> : <FaAngleDown />}
        </button>
      </h4>
      {showHideToggle && 
        <div>
          <div className="p-5">
            <p className="mb-2 text-white">{faq.answer}</p>
          </div>
        </div>
      }
    </div>
  )
}
